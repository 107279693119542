////////////////////////////
// 3rd Party Dependencies //
////////////////////////////
import { combineReducers } from "@reduxjs/toolkit";
import { ContentDataConstants, EventConfigConstants } from "../constants";
import authReducer from "./authReducer";
import { beneficiaryReducer } from "./beneficiaryReducer";
import { benefitReducer } from "./benefitReducer";
import { commonReducer } from "./commonReducer";
import coverageReducer from "./coverageReducer";
import { eventReducer } from "./eventReducer";
import { profileReducer } from "./profileReducer";
import statusReducer from "./statusReducer";
import { billingReducer } from "./billingReducer";
//////////////
// Reducers //
//////////////

///////////////
// Constants //
///////////////
const appReducer = combineReducers({
  // here we will be adding reducers
  common: commonReducer,
  status: statusReducer,
  auth: authReducer,
  coverage: coverageReducer,
  event: eventReducer,
  profile: profileReducer,
  benefit: benefitReducer,
  beneficiary: beneficiaryReducer,
  billing: billingReducer
});

export const rootReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === ContentDataConstants.GET_API_LOADER) {
    console.log("RESET REDUX...!");

    state = undefined;
  } else if (action.type === EventConfigConstants.NEXT_ENROLLMENT_STEP) {
    state.benefit.flexDollars = undefined;
  }
  /* if (action.type === AuthenticationConstants.SIGN_OUT) {
    state = undefined;
  } */

  return appReducer(state, action);
};

// Export
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
