import { useEffect } from "react";

export const SetSpinnerTransparencyClass = () => {
  useEffect(() => {
    const location = window.location.pathname;

    const path = location.replace(/^(.+?)\/*?$/, "$1");

    switch (path) {
      case "/":
      case "/logoff":
      case (path.match("/*/login") || {}).input:
      case (path.match("/*/sso") || {}).input:
        document.body.classList.add("spinner-white-bg");
        break;
      case (path.match("/*/SSO") || {}).input:
        document.body.classList.add("spinner-white-bg");
        break;
      default:
        document.body.classList.remove("spinner-white-bg");
        break;
    }

    // Clean up the class when the component unmounts
    return () => {
      document.body.classList.remove("spinner-white-bg");
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  // The component rendering is not affected by this function directly,
  // but it modifies the document body class for styling purposes.
  return null;
};

export default SetSpinnerTransparencyClass;
