/////////////////
// Helper Code //
/////////////////
// import { ApiUtils } from "../utils";

/////////////////////////////
// Session Management Code //
/////////////////////////////
// import store from "../reducers/store";s
import { ENDPOINTS } from "../constants/APIConstants";
// import { getBusinessProfileResponse } from "../constants";
import {
  EnumChangeEvent,
  EnumChangePasswordContent,
  EnumClientID,
  EnumClientStyles,
  EnumCoverageList,
  EnumCurrentCoverage,
  EnumFileUploadInfo,
  EnumFooterContent,
  EnumForgotPassword,
  EnumHeaderImages,
  EnumHelpContent,
  EnumLoginContent,
  EnumMainMenu,
  EnumPastCoverage,
  EnumWelcomeContent
} from "../actions";
import { axios } from "../utils";

/////////////
// Classes //
/////////////
/**
 * @description
 * This static class defines several static methods
 * which can be used to make API calls
 */
export class CommonApiCaller {
  /**
   * This function will get business profile data.
   *
   * @returns
   * The Promise containing the
   * Client ID.
   *
   */

  public static async clientDetails(clientName: string): Promise<EnumClientID> {
    try {
      // USER_TOKEN.removeClientName();
      // USER_TOKEN.setClientName(clientName);

      // Make the API call.
      const response: any = await axios<EnumClientID>({ method: "GET", url: ENDPOINTS.ACCOUNT.getClientId });

      console.log(`Trying to verify client name: ${clientName}`);

      // Since no errors were found in the API
      // response, return the response data.
      // const { data }: any = response;
      // console.log("response: ", response.data.result);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async clientStyles(): Promise<EnumClientStyles> {
    try {
      // Make the API call.
      const response: any = await axios<EnumClientID>({ method: "GET", url: ENDPOINTS.CONTENTAPIS.getClientStyles });

      // Since no errors were found in the API
      // response, return the response data.
      // const { data }: any = response;
      // console.log("response: ", response.data.result);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async loginContent(): Promise<EnumLoginContent> {
    try {
      // Make the API call.
      const response: any = await axios<EnumLoginContent>({
        method: "GET",
        url: ENDPOINTS.CONTENTAPIS.getloginContent
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getForgotPasswordContent(): Promise<EnumForgotPassword> {
    try {
      // Make the API call.
      const response: any = await axios<EnumForgotPassword>({
        method: "GET",
        url: ENDPOINTS.CONTENTAPIS.getforgotPasswordContent
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getTemporaryPasswordContent(): Promise<EnumChangePasswordContent> {
    try {
      // Make the API call.
      const response: any = await axios<EnumChangePasswordContent>({
        method: "GET",
        url: ENDPOINTS.CONTENTAPIS.gettemporaryPasswordContent
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async footerContent(): Promise<EnumFooterContent> {
    try {
      // Make the API call.
      const response: any = await axios<EnumFooterContent>({
        method: "GET",
        url: ENDPOINTS.CONTENTAPIS.getFooterContent
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getContentFooterPage(_section: any): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "GET",
        url: `${ENDPOINTS.CONTENTAPIS.getFooterContent}/${_section}`
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async headerImages(): Promise<EnumHeaderImages> {
    try {
      // Make the API call.
      const response: any = await axios<EnumHeaderImages>({
        method: "GET",
        url: ENDPOINTS.CONTENTAPIS.getHeaderImages
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async customPopUpURL(link: string): Promise<EnumHelpContent> {
    try {
      // Make the API call.
      const response: any = await axios<EnumHelpContent>({
        method: "GET",
        url: `${ENDPOINTS.CONTENTAPIS.getPopupURL}${link}`
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async welcomeContent(): Promise<EnumWelcomeContent> {
    try {
      // Make the API call.
      const response: any = await axios<EnumWelcomeContent>({
        method: "GET",
        url: ENDPOINTS.CONTENTAPIS.getWelcomeContent
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async mainMenuContent(_isSecondMenu: boolean): Promise<EnumMainMenu> {
    try {
      // Make the API call.
      const url = _isSecondMenu ? ENDPOINTS.CONTENTAPIS.getSecondMenu : ENDPOINTS.CONTENTAPIS.getMainMenu;
      const response: any = await axios<EnumMainMenu>({ method: "GET", url });

      /* if (response?.data?.mainMenu) {
        response.data.mainMenu.lang = USER_TOKEN.get()?.lang;
      } */
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async reviewCurrentCoverage(sublink: string): Promise<EnumCurrentCoverage> {
    try {
      // Make the API call.
      const requesturl = sublink ? `${ENDPOINTS.CONTENTAPIS.getCurrentCoverage}?${sublink}` : `${ENDPOINTS.CONTENTAPIS.getCurrentCoverage}`;
      const response: any = await axios<EnumCurrentCoverage>({ method: "GET", url: requesturl });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async reviewPastCoverage(sublink: string): Promise<EnumPastCoverage> {
    try {
      // Make the API call.
      const response: any = await axios<EnumPastCoverage>({
        method: "GET",
        url: `${ENDPOINTS.CONTENTAPIS.getPreviousCoverage}?datetime=${sublink}`
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async pastCoverageList(): Promise<EnumCoverageList> {
    try {
      // Make the API call.
      const response: any = await axios<EnumCoverageList>({
        method: "GET",
        url: `${ENDPOINTS.CONTENTAPIS.getCoverageList}`
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async finishEnrollment(formStatus: number, queryParam: string): Promise<any> {
    try {
      // Make the API call.
      const finishURL = formStatus === 1 ? `${ENDPOINTS.CONTENTAPIS.getfinishEnrollmentForm}${queryParam}` : `${ENDPOINTS.CONTENTAPIS.getfinishEnrollment}`;
      const response: any = await axios<any>({ method: "GET", url: finishURL });
      // Since no errors were found in the API
      // response, return the response data.
      return response?.data;
      //response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getFileUploadInfoContent(): Promise<EnumFileUploadInfo> {
    try {
      // Make the API call.
      const response: any = await axios<EnumFileUploadInfo>({
        method: "GET",
        url: ENDPOINTS.CONTENTAPIS.getFileUploadInfoContent
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getUploadedFilesInfoContent(): Promise<EnumFileUploadInfo> {
    try {
      // Make the API call.
      const response: any = await axios<EnumFileUploadInfo>({
        method: "GET",
        url: ENDPOINTS.CONTENTAPIS.getUploadedFilesInfoContent
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async downloadFinishedEnrollmentFile(_fileURL: string): Promise<any> {
    try {
      // Make the API call.
      const headers = {
        Accept: "application/octet-stream"
      };
      const response: any = await axios<any>({ method: "GET", url: _fileURL, headers, responseType: "blob" });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async pdfStatement(_fileURL: string, _headers: any): Promise<any> {
    try {
      const type = _fileURL.split("?");
      const response: any = await axios<any>({
        method: "GET",
        url: `${ENDPOINTS.CONTENTAPIS.getpdfStatement}${type[type.length - 1]}`,
        headers: _headers,
        responseType: "arraybuffer"
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async lifeChangeContent(): Promise<EnumChangeEvent> {
    try {
      // Make the API call.
      const response: any = await axios<EnumWelcomeContent>({
        method: "GET",
        url: ENDPOINTS.CONTENTAPIS.getLifeChangeEvent
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  public static async DownloadUploadDocuments(fileID: any, fileName: string): Promise<any> {
    try {
      console.log(fileName);
      // Make the API call.
      const headers = {
        Accept: "application/octet-stream"
      };
      const response: any = await axios<any>({
        method: "GET",
        url: `${ENDPOINTS.CONTENTAPIS.downloadUploadDocuments}${fileID}`,
        headers,
        responseType: "blob"
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteEnrollmentFooter(): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({ method: "DELETE", url: ENDPOINTS.BENEFITS.deleteEnrollment });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async updateWelcomeContent(_lifeChange: any, _headers: any): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "PUT",
        url: ENDPOINTS.CONTENTAPIS.getWelcomeContent,
        data: _lifeChange,
        headers: _headers
      });

      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async putWelcomeMessages(_lifeChange: any): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "PUT",
        url: ENDPOINTS.CONTENTAPIS.putWelcomeMessages,
        data: _lifeChange
      });

      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async nextEnrollmentStep(): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<EnumFileUploadInfo>({
        method: "GET",
        url: ENDPOINTS.BENEFITS.nextEnrollmentStep
      });

      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async previousEnrollmentStep(): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<EnumFileUploadInfo>({
        method: "GET",
        url: ENDPOINTS.BENEFITS.previousEnrollmentStep
      });

      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async progressIndicator(): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<EnumFileUploadInfo>({
        method: "GET",
        url: ENDPOINTS.BENEFITS.progressIndicator
      });

      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default CommonApiCaller;
