////////////////////////////
// 3rd Party Dependencies //
////////////////////////////
import type { AxiosRequestConfig } from "axios";
import axiosClient, { AxiosInstance } from "axios";

import { loading, idleTimer } from "./../actions";

/////////////////
// Helper Code //
/////////////////

/////////////////////
// Constant Values //
/////////////////////
import { NavigateFunction } from "react-router-dom";
import { DEFAULT_HEADER, USER_TOKEN } from "./Storage";
import { ENDPOINTS } from "../constants";
// import { debug } from "util";

////////////////////
// Initialization //
////////////////////
// Define the default AXIOS properties for all API calls.
// console.log(process.env);

// const path = require(process.env.PUBLIC_URL);

// console.log("EndPoint URL: ", window.REST_API_ENDPOINTS.api_server);
const { api_server }: any = window.global;
const production_server = api_server;
let numberOfAjaxCAllPending = 0;

/**
 *
 * Change server_address value if you want change the service endpoint:
 * * production_server: Flexit360 Production endpoint,
 * * stage_server: ML staging server,
 * * mock_server:localhost, you need to run the mock api project on your machine on port 1024.
 *
 * */
const server_address = production_server;

////////////////
// Interfaces //
////////////////
declare global {
  interface Window {
    chrome: any;
    opr: any;
  }
}

/////////////
// Classes //
/////////////
/**
 * @description
 * This static class defines several static methods
 * which can be used to make API calls.
 */
/* export const ApiUtils = () => {
  try { */
console.log(`* * * Rest API Endpoint:${production_server} * * *`);
// const axiosResponse = await ApiUtils.axiosInstance.request(axiosOptions);
const instance: AxiosInstance = axiosClient.create({
  baseURL: server_address,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN"
});

const getLoginHistory = () => {
  // let nVer = navigator.appVersion;
  const userAgent = navigator.userAgent;

  let browserVersion = "" + parseFloat(navigator.appVersion);
  let majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset, verOffset, ix;

  const browserCodeName = (function (agent) {
    switch (true) {
      case agent.indexOf("edge") > -1:
        verOffset = agent.indexOf("edge");
        browserVersion = agent.substring(verOffset + 4);
        return "Edge";
      case agent.indexOf("edg/") > -1:
        verOffset = agent.indexOf("edg/");
        browserVersion = agent.substring(verOffset + 4);
        return "Edge";
      case agent.indexOf("opr") > -1 && !!window.opr:
        verOffset = agent.indexOf("opr");
        return "Opera";
      case agent.indexOf("chrome") > -1 && !!window.chrome:
        verOffset = agent.indexOf("chrome");
        browserVersion = agent.substring(verOffset + 7);
        return "Google Chrome";
      case agent.indexOf("trident") > -1:
        verOffset = agent.indexOf("trident");
        browserVersion = agent.substring(verOffset + 5);
        return "MS IE";
      case agent.indexOf("firefox") > -1:
        verOffset = agent.indexOf("firefox");
        return "Mozilla Firefox";
      case agent.indexOf("safari") > -1:
        verOffset = agent.indexOf("safari");
        browserVersion = agent.substring(verOffset + 7);
        if ((verOffset = agent.indexOf("Version")) != -1) browserVersion = agent.substring(verOffset + 8);
        return "Safari";
      default:
        if ((nameOffset = agent.lastIndexOf(" ") + 1) < (verOffset = agent.lastIndexOf("/"))) {
          browserVersion = agent.substring(verOffset + 1);
        }
        return agent.substring(nameOffset, verOffset);
      // return "Other";
    }
  })(userAgent.toLowerCase());

  // trim the browserVersion string at semicolon/space if present
  if ((ix = browserVersion.indexOf(";")) != -1) browserVersion = browserVersion.substring(0, ix);
  if ((ix = browserVersion.indexOf(" ")) != -1) browserVersion = browserVersion.substring(0, ix);
  // console.log("browser_version = ", browserVersion);

  majorVersion = parseInt("" + browserVersion, 10);
  if (isNaN(majorVersion)) {
    browserVersion = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  let OSName = "Unknown OS";
  // console.log("userAgent: ", userAgent);
  if (navigator.appVersion.indexOf("Win") != -1) OSName = "Microsoft Windows";
  if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
  if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
  if (/android/i.test(userAgent)) OSName = "Android";
  if (/iPad|iPhone|iPod/.test(userAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) OSName = "iOS";

  let viewportOrientation = "";
  if (window.matchMedia("(orientation: portrait)").matches) viewportOrientation = "portrait";
  if (window.matchMedia("(orientation: landscape)").matches) viewportOrientation = "landscape";

  return JSON.stringify({
    browserCodeName,
    browserName: navigator.appName,
    browserVersion,
    majorVersion,
    browserCookieEnabled: navigator.cookieEnabled,
    browserLanguage: navigator.language,
    isOnline: navigator.onLine,
    Platform: navigator.platform,
    OSName,
    screenResolution: window.innerWidth + "x" + window.innerHeight,
    viewportOrientation
  });
};

/** IMPORTANT */
export const AxiosInterceptorsSetup = (navigate: NavigateFunction) => {
  instance.interceptors.request.use(
    (config: any) => {
      numberOfAjaxCAllPending++;
      loading(true);
      const isSignedIn = sessionStorage.getItem("isSignedIn");
      if (config?.url !== "/Account/refreshtoken" && isSignedIn) {
        // console.log("isSignedIn? ", isSignedIn);
        idleTimer(navigate);
      }
      // console.log("----> HEADER BEFORE: ", config.headers)
      // console.log("location: ", location?.slice(1, location?.lastIndexOf("/") - 0));

      if (config?.headers?.DeviceType) {
        const deviceType = config.headers.DeviceType;
        config.headers = DEFAULT_HEADER.get();
        config.headers.DeviceType = deviceType;
      } else {
        config.headers = DEFAULT_HEADER.get();
        const loginHistory = sessionStorage.getItem("loginHistory");
        if (config?.url === `/${ENDPOINTS.CONTENTAPIS.getWelcomeContent}` && !loginHistory) {
          // console.log("Login History: ", JSON.parse(getLoginHistory()));
          sessionStorage.setItem("loginHistory", "true");
          config.headers.LoginHistory = getLoginHistory();
        }
      }
      if (config.url == "FileUpload/UploadFile/") {
        config.headers["Content-type"] = "multipart/form-data;";
      }

      //console.log("<----> HEADER AFTER: ", config.headers)
      return config;
    },
    (error) => {
      loading(false);
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    async (response) => {
      numberOfAjaxCAllPending--;

      if (numberOfAjaxCAllPending === 0) {
        loading(false);
      }

      return response;
    },
    async (error) => {
      const { client } = USER_TOKEN?.get();
      loading(false);
      const { response } = error;
      if (response?.status === 401 || response?.status === 403) {
        USER_TOKEN?.remove();
        navigate("/logoff");
        navigate(`/${client}/login`);
      } else if (response?.status === 400) {
        console.error("Bad Request: ", response.data);
        navigate("/logoff");
        navigate(`/${client}/login`);
      } else {
        console.error(`Error: ${response?.status}`, response?.data);
      }

      return Promise.reject(error);
    }
  );
};

export const axios = <T>(cfg: AxiosRequestConfig) => instance.request<any, T>(cfg);

export default axios;
