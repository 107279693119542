////////////////////////////
// 3rd Party Dependencies //
////////////////////////////
import { configureStore } from "@reduxjs/toolkit";
// import logger from "redux-logger";
import thunkMiddleware from "redux-thunk";
///////////////////////////
// Internal Dependencies //
///////////////////////////
import { rootReducer } from "../reducers";

///////////////
// Constants //
///////////////
// const middleware = [thunkMiddleware];

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).prepend(thunkMiddleware)
  // .concat(logger)
  // middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), thunkMiddleware].concat(logger)
  // middleware: new MiddlewareArray().concat(thunkMiddleware, logger)
});

////////////
// Export //
////////////
export default store;
