import { ENDPOINTS } from "../constants/APIConstants";
// import { getBusinessProfileResponse } from "../constants";
import {
  EnumProfileContent,
  EnumDependentContent,
  EnumProfile,
  EnumProfileResponse,
  EnumDependentResponse
} from "../actions";
import { axios } from "../utils";

/////////////
// Classes //
/////////////
/**
 * @description
 * This static class defines several static methods
 * which can be used to make API calls
 */
export class ProfileApiCaller {
  public static async getProfile(_isReview: boolean): Promise<EnumProfileContent> {
    try {
      // console.log("_isReview: ", _isReview);
      // Make the API call.
      const response: any = await axios<EnumProfileContent>({
        method: "GET",
        url: ENDPOINTS.PROFILEAPIS.getProfile + _isReview
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getDependents(_dependentType: string): Promise<EnumDependentContent> {
    try {
      // console.log("dependentType", _dependentType);
      const dependentURL =
        _dependentType === "updatestudent"
          ? ENDPOINTS.PROFILEAPIS.getupdateDependents + "?type=updatestudent"
          : ENDPOINTS.PROFILEAPIS.getupdateDependents;
      // Make the API call.
      const response: any = await axios<EnumDependentContent>({
        method: "GET",
        url: dependentURL
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async updatePersonalInfo(_profile: EnumProfile): Promise<EnumProfileResponse> {
    try {
      // Make the API call.
      const response: any = await axios<EnumProfileResponse>({
        method: "PUT",
        url: ENDPOINTS.PROFILEAPIS.updateProfile,
        data: _profile
      });

      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async updateDependent(_user: any, _dependentType: string): Promise<any> {
    try {
      const dependentURL =
        _dependentType === "updatestudent"
          ? ENDPOINTS.PROFILEAPIS.getupdateDependents + "?type=updatestudent"
          : ENDPOINTS.PROFILEAPIS.getupdateDependents;

      // Make the API call.
      const response: any = await axios<any>({
        method: "PUT",
        url: dependentURL,
        data: _user
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteDependent(_dependentId: number): Promise<EnumDependentResponse> {
    try {
      // Make the API call.
      const response: any = await axios<EnumDependentResponse>({
        method: "DELETE",
        url: ENDPOINTS.PROFILEAPIS.getupdateDependents + "/" + _dependentId,
        data: _dependentId
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async AddNewDependent(dependent: any): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "POST",
        url: ENDPOINTS.PROFILEAPIS.getupdateDependents,
        data: dependent
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getSummaryDependents(): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.PROFILEAPIS.getSummaryDependents}`;
      const response: any = await axios<any>({
        method: "GET",
        url: _url
      });
      // Since no errors were found in the API
      // response, return the response data.
      console.log(response?.data);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getSummaryPersonal(): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.PROFILEAPIS.getSummaryPersonal}`;
      const response: any = await axios<any>({
        method: "GET",
        url: _url
      });
      // Since no errors were found in the API
      // response, return the response data.
      console.log(response?.data);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
