export function getAccountErrorMsg({ lblAccountNumberError, lblAccountNumberMaxMinError }) {
  return (replaced_max_length: any, replaced_min_length?: number) => {
    let errorMsg = {};
    let replaced_error_display = lblAccountNumberError?.display;
    const replaced_error_text = lblAccountNumberError?.textToRead;
    let replaced_mixmax_error_display = lblAccountNumberMaxMinError?.display;
    const replaced_minmax_error_text = lblAccountNumberMaxMinError?.textToRead;
    if (replaced_max_length != undefined && replaced_min_length !== undefined && replaced_max_length !== replaced_min_length) {
      // Regular expression to match [!MaxNumber]
      const regexMax = /\[\!MaxNumber\]/g;
      // Regular expression to match [!MinNumber]
      const regexMin = /\[\!MinNumber\]/g;
      if (replaced_mixmax_error_display !== undefined && replaced_mixmax_error_display !== null) {
        replaced_mixmax_error_display = replaced_mixmax_error_display?.replace(regexMax, replaced_max_length.toString());
        replaced_mixmax_error_display = replaced_mixmax_error_display?.replace(regexMin, replaced_min_length.toString());
        errorMsg = {
          display: replaced_mixmax_error_display,
          textToRead: replaced_minmax_error_text
        };
      }
    } else if (replaced_max_length !== undefined) {
      // Regular expression to match [!MaxNumber]
      const regex = /\[\!MaxNumber\]/g;

      if (replaced_error_display !== undefined && replaced_error_display !== null) {
        replaced_error_display = replaced_error_display?.replace(regex, replaced_max_length.toString());

        errorMsg = {
          display: replaced_error_display,
          textToRead: replaced_error_text
        };
      }
    }

    return errorMsg;
  };
}
