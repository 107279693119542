// Imports
import { AuthenticationActionTypes } from "../actions";
import { AuthenticationConstants } from "../constants";
import { AuthConfigState } from "../interfaces";
import { USER_TOKEN } from "../utils/Storage";

// Constants
/**
 * This defines the default state
 * for the AppConfig.
 */

const defaultState: AuthConfigState = {
  authResponse: null,
  isSignedIn: false,
  resetPasswordResponse: null,
  redirectURL: null,
  locale: USER_TOKEN.get().lang,
  twoFARegContent: {},
  regContent: {},
  verificationContent: {},
  tfaSettings: {},
  disableTFAContent: null,
  needTwoFARegistration: USER_TOKEN.get().needTwoFARegistration,
  needTwoFAValidation: USER_TOKEN.get().needTwoFAValidation,
  isOpen: false
};

//Functions
export const authReducer = (state: AuthConfigState = defaultState, action: AuthenticationActionTypes): AuthConfigState => {
  switch (action.type) {
    case AuthenticationConstants.REFRESH_TOKEN:
    case AuthenticationConstants.SIGN_IN:
      return {
        ...state,
        authResponse: action.payload,
        isSignedIn: USER_TOKEN.get().isSignedIn ? true : false,
        needTwoFARegistration: USER_TOKEN.get().needTwoFARegistration,
        needTwoFAValidation: USER_TOKEN.get().needTwoFAValidation
      };

    /* case AuthenticationConstants.NEED_TFA:
        return {
          ...state,
          needTwoFARegistration:false,
          needTwoFAValidation: false
        }; */

    case AuthenticationConstants.SIGN_OUT: {
      return {
        ...state,
        redirectURL: action.payload
      };
    }
    case AuthenticationConstants.CHANGE_PASSWORD:
    case AuthenticationConstants.RESET_PASSWORD:
      return {
        ...state,
        resetPasswordResponse: action.payload
      };

    case AuthenticationConstants.PASSWORD_CHANGED:
      return {
        ...state,
        authResponse: { ...state.authResponse, passwordChangeRequired: action.payload }
      };

    case AuthenticationConstants.CHANGE_LOCALE: {
      USER_TOKEN.setLang(`${action.payload}-CA`);
      // setDefaultLocale(action.payload);
      return {
        ...state,
        locale: action.payload
      };
    }
    case AuthenticationConstants.TOGGLE_SIDEBAR: {
      return {
        ...state,
        isOpen: action.payload
      };
    }
    case AuthenticationConstants.GET_TWOFA_REG_CONTENT:
      return {
        ...state,
        // TwoFARegistrationResponse: action.payload,
        twoFARegContent: action.payload
      };

    case AuthenticationConstants.GET_REGISTRATION_FORM:
      return {
        ...state,
        regContent: action.payload
      };

    case AuthenticationConstants.GET_VERIFICATION_CONTENT:
      return {
        ...state,
        verificationContent: action.payload
      };

    case AuthenticationConstants.GET_TFA_SETTINGS:
      return {
        ...state,
        tfaSettings: action.payload
      };

    case AuthenticationConstants.TFA_DISABLE:
      return {
        ...state,
        disableTFAContent: action.payload
      };

    default:
      return state;
  }
};

// Export
export default authReducer;
