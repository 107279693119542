/////////////////
// Helper Code //
/////////////////
// import { ApiUtils } from "../utils";

/////////////////////////////
// Session Management Code //
/////////////////////////////
import { ENDPOINTS } from "../constants/APIConstants";
// import { getBusinessProfileResponse } from "../constants";
import { EnumResetPassword, EnumUserChangePassword, EnumUserLoginCard, EnumTwoFARegistration } from "../actions";
import { axios } from "../utils";

/////////////
// Classes //
/////////////
/**
 * @description
 * This static class defines several static methods
 * which can be used to make API calls
 */
export class AuthApiCaller {
  /**
   * This function will get business profile data.
   *
   * @returns
   * The Promise containing the
   * Client ID.
   *
   */
  public static async resetPassword(_userName: string): Promise<EnumResetPassword> {
    //const req={Login:userName};
    try {
      // Make the API call.
      const response: any = await axios<EnumResetPassword>({
        method: "PUT",
        url: ENDPOINTS.AUTH.resetPassword,
        data: {
          LoginID: _userName
        }
      });

      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async changePassword(_password: string, _tfaDate: any): Promise<EnumUserChangePassword> {
    try {
      // Make the API call.
      const response: any = await axios<EnumUserChangePassword>({
        method: "PUT",
        url: ENDPOINTS.AUTH.changePassword,
        data: {
          newPassword: _password,
          TwoFAValidatedDate: _tfaDate
        }
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async login(_username: string, _password: string, _tfaDate: any): Promise<EnumUserLoginCard> {
    try {
      // Make the API call.
      const response: any = await axios<EnumUserLoginCard>({
        method: "POST",
        url: ENDPOINTS.AUTH.authenticate,
        data: {
          Username: _username,
          Password: _password,
          TwoFAValidatedDate: _tfaDate
        }
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async refreshTokenRoute(_token: string, _refreshToken: string): Promise<EnumUserLoginCard> {
    try {
      // Make the API call.
      const response: any = await axios<EnumUserLoginCard>({
        method: "POST",
        url: ENDPOINTS.AUTH.refreshTokenRoute,
        data: {
          token: _token,
          refreshToken: _refreshToken
        }
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async logout(): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "DELETE",
        url: ENDPOINTS.AUTH.authenticate
      });
      // Since no errors were found in the API
      // response, return the response data.

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async uploadEmployeeDocuments(fileData: FormData): Promise<any> {
    const headers = {
      "Content-Type": "multipart/form-data"
    };

    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "POST",
        url: ENDPOINTS.CONTENTAPIS.uploadFile,
        data: fileData,
        headers: headers
      });

      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getTFARegContent(): Promise<EnumTwoFARegistration> {
    try {
      // Make the API call.
      const response: any = await axios<EnumTwoFARegistration>({
        method: "GET",
        url: ENDPOINTS.AUTH.getTFARegContent
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async TwoFARegistrationSelection(isRegistration: any, twoTypeID: any): Promise<EnumTwoFARegistration> {
    try {
      // Make the API call.

      const URL = ENDPOINTS.AUTH.getTFARegSelection + "?isRegistration=" + isRegistration + "&selected=" + twoTypeID;

      const response: any = await axios<EnumTwoFARegistration>({
        method: "GET",
        url: URL
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async sendVerificationCode(options: any): Promise<EnumTwoFARegistration> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "POST",
        url: ENDPOINTS.AUTH.sendTwaFAVerificationCode,
        data: options
      });
      // Since no errors were found in the API
      // response, return the response data.

      return response.data;

      console.log(history);
    } catch (error) {
      throw error;
    }
  }

  public static async getTFAVerificationContent(params: any): Promise<any> {
    try {
      //;
      // Make the API call.
      const response: any = await axios<any>({
        method: "GET",
        url: ENDPOINTS.AUTH.getTFAVerificationContent + params
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  public static async validateTFACode(options: any): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "POST",
        url: ENDPOINTS.AUTH.validateTFACode,
        data: options
      });
      // Since no errors were found in the API
      // response, return the response data.
      //;
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getTwoFASettings(): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "GET",
        url: ENDPOINTS.AUTH.getTwoFASettings
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async disableTwoFA(): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "PUT",
        url: ENDPOINTS.AUTH.disableTwoFA
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getSSOtokenCaller(_req: any): Promise<any> {
    try {
      const locale = _req?.lang?.split("-")[0];
      const headers = { Lang: `${locale}-CA` };
      console.log("_req: ", _req);
      // Make the API call.
      const response: any = await axios<any>({
        method: "POST",
        url: ENDPOINTS.AUTH.getssotoken,
        data: { uid: _req?.u },
        headers: headers
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default AuthApiCaller;
