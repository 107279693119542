import { ENDPOINTS } from "../constants";
// import { getBusinessProfileResponse } from "../constants";
import { EnumBenefitWellnessInfo, EnumEnrollmentHeader, showError } from "../actions";
import { axios } from "../utils";
// import EnrollmentHeader from "../containers/Benefit/Mock/EnrollmentHeader.json";

/////////////
// Classes //
/////////////
/**
 * @description
 * This static class defines several static methods
 * which can be used to make API calls
 */
export class BenefitApiCaller {
  public static async getBenefitWellnessInfos(): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<EnumBenefitWellnessInfo>({
        method: "GET",
        url: ENDPOINTS.BENEFITS.getWellness
      });

      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  public static async updateBenefitWellness(wellnessQuestionAnswers: any): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "PUT",
        url: ENDPOINTS.BENEFITS.updateWellness,
        data: wellnessQuestionAnswers
      });

      // Since no errors were found in the API
      // response, return the response data.
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getShowFlexApi(): Promise<EnumEnrollmentHeader> {
    try {
      //console.log("benefitid",benefitid)
      // Make the API call.
      const response: any = await axios<EnumEnrollmentHeader>({
        method: "GET",
        url: ENDPOINTS.BENEFITS.getShowFlex
      });
      // Since no errors were found in the API
      // console.log(response);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async setMultiBenefitOption(_benefitName: string, _reqParams: any): Promise<any> {
    try {
      const url = `/${_benefitName}${ENDPOINTS.BENEFITS.changeOption}`;
      // Make the API call.
      const response: any = await axios<any>({
        method: "PUT",
        url,
        data: _reqParams
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async saveWaiverForm(_benefitName: string, _reqParams: any, _type: boolean): Promise<any> {
    try {
      const _url = `/${_benefitName}${_type ? ENDPOINTS.BENEFITS.optOutWaiver : ENDPOINTS.BENEFITS.coverageWaiver}`;
      // Make the API call.
      const response: any = await axios<any>({
        method: "PUT",
        url: _url,
        data: _reqParams
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getBenefitWithURL(benefitName: any, paramName: string | undefined, benefitid: undefined): Promise<any> {
    try {
      const _url = `/${benefitName}?${paramName}=${benefitid}`;

      // Make the API call.
      const response: any = await axios<any>({
        method: "GET",
        url: _url
      });

      // Since no errors were found in the API
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async saveSmokerBenefit(_body: any): Promise<any> {
    try {
      const saveBenefitUrl = `${ENDPOINTS.BENEFITS.saveSmokerBenefit}`;
      // Make the API call.
      const response: any = await axios<any>({
        method: "POST",
        url: saveBenefitUrl,
        data: _body
      });
      // Since no errors were found in the API
      console.log(response);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async removeWaiver(_benefitURL: string): Promise<any> {
    try {
      const [benefitName, benefitid] = _benefitURL ? _benefitURL.split("/") : []; //'getState().userReducer.pageSetting.link.split("/")'
      const url = `/${benefitName}${ENDPOINTS.BENEFITS.removeWaiver}`;
      // Make the API call.
      const response: any = await axios<any>({
        method: "PUT",
        url: url,
        data: { benefitID: benefitid, optionID: "0" }
      });
      // Since no errors were found in the API
      console.log(response);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async removeEOIOption(_benefitURL: string): Promise<any> {
    try {
      const [benefitName, benefitid] = _benefitURL ? _benefitURL.split("/") : []; //'getState().userReducer.pageSetting.link.split("/")'
      const url = `/${benefitName}${ENDPOINTS.BENEFITS.removeEOIOption}${benefitid}`;
      // Make the API call.
      const response: any = await axios<any>({
        method: "PUT",
        url: url
      });
      // Since no errors were found in the API
      console.log(response);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async saveChangeDependentCoverage(_benefitName: any, _reqParams: any): Promise<any> {
    try {
      const url = `/${_benefitName}${ENDPOINTS.BENEFITS.dependentCoverageChange}`;
      // Make the API call.
      const response: any = await axios<any>({
        method: "PUT",
        url: url,
        data: _reqParams
      });
      // Since no errors were found in the API
      console.log(response);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getSmokerContent(): Promise<any> {
    try {
      const url = `${ENDPOINTS.BENEFITS.getSmokerContent}`;
      // Make the API call.
      const response: any = await axios<any>({
        method: "GET",
        url: url
      });
      // Since no errors were found in the API
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async saveBenefit(_benefitName: string, body: object): Promise<any> {
    try {
      const url = `/${_benefitName}${ENDPOINTS.BENEFITS.saveBenefit}`;
      // Make the API call.
      const response: any = await axios<any>({
        method: "POST",
        url,
        data: body
      });
      if (response?.data && response?.data?.statusCode === 500) {
        const message = response.data.errorMessage;
        showError(message);
      }
      // Since no errors were found in the API
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async saveFlexBenefit(flexGroupID: number, flexItemList: any): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "POST",
        url: ENDPOINTS.FLEX.saveFlex,
        data: { flexGroupID, flexItemList }
      });
      // Since no errors were found in the API
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getFlexBenefit(groupID: any): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "GET",
        url: ENDPOINTS.FLEX.getFlex + `${groupID}`
      });
      // Since no errors were found in the API
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async updateFlexBenefit(payload: any): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "PUT",
        url: ENDPOINTS.FLEX.updateFlex,
        data: payload
      });
      // Since no errors were found in the API
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async reallocateFlexBenefit(payload: any): Promise<any> {
    try {
      // Make the API call.
      const response: any = await axios<any>({
        method: "PUT",
        url: ENDPOINTS.FLEX.reallocateFlex,
        data: payload
      });
      // Since no errors were found in the API
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getBenefitSummary(): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFICIARYAPIS.getBenefitSummary}`;
      const response: any = await axios<any>({
        method: "GET",
        url: _url
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response?.data;
      //response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getSummaryBenefits(): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFICIARYAPIS.getSummaryBenefits}`;
      const response: any = await axios<any>({
        method: "GET",
        url: _url
      });

      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getEnrollmentFooter(): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFITS.confirmEnrollment}`;
      const response: any = await axios<any>({
        method: "GET",
        url: _url
      });
      // Since no errors were found in the API
      // response, return the response data.
      console.log(response?.data);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async confirmEnrollment(): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFITS.confirmEnrollment}`;
      const response: any = await axios<any>({
        method: "POST",
        url: _url
      });
      // Since no errors were found in the API
      // response, return the response data.
      console.log(response?.data);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getSummaryFlex(): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFICIARYAPIS.getSummaryFlex}`;
      const response: any = await axios<any>({
        method: "GET",
        url: _url
      });
      // Since no errors were found in the API
      // response, return the response data.

      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
