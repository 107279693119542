/////////////
// Classes //
/////////////

import ObjectUtils from "./ObjectUtils";

/**
 * @description
 * This static class defines several static methods
 * which can be used to work with strings.
 */
export class StringUtils {
  /* ******************
   * PUBLIC FUNCTIONS *
   ********************/
  /**
   * @description
   * This function will determine if the
   * specified string is empty.
   *
   * @param {string} input
   * The string to check.
   * @param {boolean} trimFirst
   * The boolean flag to denote if the input
   * string should be trimmed before being
   * checked.
   */
  public static isEmpty(input?: string | number | null, trimFirst?: boolean): boolean {
    // If the input is null or undefined,
    // return false.
    if (ObjectUtils.isNull(input)) {
      return true;
    }

    // If the input is a string, is not
    // null and trimming is wanted, then
    // trim the input.
    if (typeof input === "string" && typeof trimFirst === "boolean" && trimFirst !== null && true === trimFirst) {
      input = input.trim();
    }

    // If the input is a number, then
    // append it to an empty string.
    if (typeof input === "number") {
      input = "" + input;
    }

    // If the input (possibly trimmed),
    // is not a string, or it null or has
    // length of 0, then return true;
    return typeof input !== "string" || input.length === 0;
  }

  /**
   * This function will apply padding to the
   * left-side of the specified input string
   * and possibly trim excess characters.
   *
   * @param input
   * The input string.
   * @param padStr
   * The padding string.
   * @param maxLength
   * the maximum length of the output string.
   * @param trimExcess
   * The boolean flag to denote if any excess
   * characters that already exist on the input
   * string should be trimmed off.
   *
   * @returns
   * The padded string.
   */
  public static padLeft(input: string, padStr: string, maxLength: number, trimExcess: boolean): string {
    return StringUtils.pad(true, input, padStr, maxLength, trimExcess);
  }

  /* *******************
   * PRIVATE FUNCTIONS *
   *********************/
  /**
   * This function will apply padding to the
   * left-side of the specified input string
   * and possibly trim excess characters.
   *
   * @param padLeft
   * The boolean flag to denote if the
   * padding/trimming should be done on the
   * left.
   * @param input
   * The input string.
   * @param padStr
   * The padding string.
   * @param maxLength
   * the maximum length of the output string.
   * @param trimExcess
   * The boolean flag to denote if any excess
   * characters that already exist on the input
   * string should be trimmed off.
   *
   * @returns
   * The padded string.
   */
  private static pad(padLeft: boolean, input: string, padStr: string, maxLength: number, trimExcess: boolean): string {
    // If the input length is equal to or exceeds
    // the maximum length, then we don't need to
    // pad anything.
    if (input.length >= maxLength) {
      // If we need to trim excessive values,
      // then do so.
      if (trimExcess && input.length > maxLength) {
        if (padLeft) {
          return input.substring(input.length - maxLength);
        } else {
          return input.substring(0, maxLength);
        }
      }

      return input;
    }

    // Determine how many characters need to be
    // padded to the input string.
    let padCount = maxLength - input.length;

    // Loop until we've added all of the padding
    // characters needed.
    while (padCount > 0) {
      // If the padding string length is
      // less-than-or-equal-to the padding needed,
      // then just add the entire padding string.
      if (padStr.length <= padCount) {
        if (padLeft) {
          input = padStr + input;
        } else {
          input += padStr;
        }
      }
      // If the padding string length is
      // longer-than the padding needed, then just
      // add the number of string needed from the
      // padding string.
      else {
        if (padLeft) {
          input = padStr.substring(0, padCount) + input;
        } else {
          input += padStr.substring(0, padCount);
        }
      }

      // Drop the padding count by the padding string.
      // It's ok if this goes below 0 in the case of
      // the above else condition since this will still
      // get out of the while-loop.
      padCount -= padStr.length;
    }

    return input;
  }

  static checkBrowser(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    const trident = ua.indexOf("Trident/");
    const msEdge = ua.indexOf("Edge");

    return msie > 0 || trident > 0 || msEdge > 0;
  }
}

export default StringUtils;
