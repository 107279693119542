/////////////
// Classes //
/////////////
import _ from "underscore";
import StringUtils from "./StringUtils";
import { EnumMainMenu } from "../actions";

/**
 * @description
 * This static class defines several static methods
 * which can be used to work with objects.
 */
export class ObjectUtils {
  /* *******************
   * PRIVATE CONSTANTS *
   *********************/
  /**
   * @description
   * This constant contains an array of
   * all possible string values that are
   * equivilent to true.
   */
  private static readonly ISTRUE_STRINGVALUES = ["true", "on", "yes", "y", "1"];

  /* ******************
   * PUBLIC FUNCTIONS *
   ********************/
  /**
   * @description
   * This function will determine if the
   * specified input is either null or
   * undefined.
   *
   * @param {any} input
   * The input to check.
   *
   * @returns
   * The boolean flag to denote if the
   * specified input is either null or
   * undefined.
   */
  public static isNull(input: any | null | undefined): boolean {
    return typeof input === "undefined" || input === undefined || input === null;
  }

  /**
   * @description
   * This function will determine if the
   * specified input is a number.
   *
   * @param {any} input
   * The input to check.
   *
   * @returns
   * The boolean flag to denote if the
   * specified input is a number.
   */
  public static isNumber(input: any | null | undefined): boolean {
    return typeof input === "number" && input !== null;
  }

  /**
   * @description
   * This function will determine if the
   * specified input represents a boolean
   * true.
   *
   * @param {any} input
   * The input to check.
   *
   * @returns
   * The boolean flag to denote if the
   * specified input represents a boolean
   * true.
   */
  public static isTrue(input: any | null | undefined): boolean {
    // If the input is a boolean value, then
    // just return if it's true.
    if (typeof input === "boolean" && input !== null) {
      return true === input;
    }
    // If the input is a number value, then
    // return true if it's greater than 0,
    // false otherwise.
    else if (typeof input === "number" && input !== null) {
      return input > 0;
    }
    // If the input is a string value, then
    // return true if it matches any acceptable
    // string equivalent value for true.
    else if (typeof input === "string" && input !== null) {
      input = input.trim().toLowerCase();

      for (let n = 0; n < ObjectUtils.ISTRUE_STRINGVALUES.length; n++) {
        if (input === ObjectUtils.ISTRUE_STRINGVALUES[n]) {
          return true;
        }
      }
    }

    // Return false since the input doesn't
    // appear to be a boolean representation.
    return false;
  }

  /**
   * @description
   * This function will determine if the
   * specified input is a non-empty array.
   *
   * @param input
   * The input.
   *
   * @returns
   * The boolean flag to denote if the
   * specified input is a non-empty array.
   */
  public static isListNotEmpty(input?: any | null | undefined): boolean {
    if (typeof input !== "undefined" && input !== null && Array.isArray(input)) {
      return input.length > 0;
    }

    return false;
  }

  /**
   * @description
   * This function will create query-string
   * encoding of a Javascript object.
   *
   * @param obj
   * The input object which need to
   * convert into query string.
   *
   * @returns
   * The query string from given object.
   */
  public static serialize(obj: any) {
    const str: string[] = [];
    for (const p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  /**
   * @description
   * This function will determine if the
   * specified both inputs are equal.
   *
   * @param {any} x
   * The first input to compare.
   * @param {any} y
   * The second input to compare.
   *
   * @returns
   * The boolean flag to denote if the
   * specified both inputs are equal.
   */
  public static isEquals(x: any, y: any): boolean {
    if (x === null || x === undefined || y === null || y === undefined) {
      return x === y;
    }
    if (x !== x && y !== y) return true;
    if (typeof x !== typeof y) return false;
    if (x.constructor !== y.constructor) return false;

    // if they are functions, they should exactly
    //  refer to same one (because of closures)
    if (x instanceof Function) {
      return x === y;
    }
    // if they are regexps, they should exactly
    // refer to same one (it is hard to better
    // equality check on current ES)
    if (x instanceof RegExp) {
      return x === y;
    }
    if (x === y || x.valueOf() === y.valueOf()) {
      return true;
    }
    if (Array.isArray(x) && x.length !== y.length) {
      return false;
    }

    // if they are dates, they must had
    // equal valueOf
    if (x instanceof Date) {
      return false;
    }

    // if they are strictly equal, they both
    // need to be object at least
    if (!(x instanceof Object)) {
      return false;
    }
    if (!(y instanceof Object)) {
      return false;
    }

    // recursive object equality check
    const p = Object.keys(x);

    return (
      Object.keys(y).every(function (i) {
        return p.indexOf(i) !== -1;
      }) &&
      p.every(function (i) {
        return ObjectUtils.isEquals(x[i], y[i]);
      })
    );
  }

  /**
   * @description
   * This function will determine if the
   * given input object is empty or not.
   *
   * @param obj
   * The input object
   *
   * @returns
   * The boolean flag to denote if the
   * specified input object is empty
   * or not.
   */
  public static isEmpty(obj: object) {
    // Return true when input object is
    // null or undefined.
    if (typeof obj === "undefined" || obj === null) return true;

    // Return false when object has a
    // property.
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
    }

    return true;
  }

  public static isTest(obj: any, pattern: any) {
    const regex = new RegExp(pattern);
    if (regex.test(obj) || obj == undefined) {
      return false;
    }
    return true;
  }

  /**
   * This function will decode and parse the
   * specified JSON Web Token and return the
   * payload.
   *
   * @param token
   * The JSON Web Token as a string.
   *
   * @returns
   * The JSON object containing the data
   * within the JSON Web Token.
   * This will be undefined if the token is
   * not valid.
   */
  public static parseJwt(token?: string | null): object | undefined {
    // If the token is empty, then return
    // undefined.
    if (StringUtils.isEmpty(token, true)) {
      return undefined;
    }

    // Split the token into its expected 3
    // sections.
    const tokenSections = token!.split(".");

    // If the token doesn't contain the expected
    // 3 sections, return undefined.
    if (!tokenSections || tokenSections.length !== 3) {
      return undefined;
    }

    // Ensure only valid characters are present.
    const base64 = tokenSections[1].replace("-", "+").replace("_", "/");

    // Decode the Base64 and return the parsed
    // JSON object.
    return JSON.parse(window.atob(base64));
  }

  /**
   * This function will decode and parse the
   * specified JSON Web Token and return the
   * username from the payload.
   *
   * @param token
   * The JSON Web Token as a string.
   *
   * @returns
   * The username found within the JSON Web
   * Token.
   * This will be undefined if either the
   * token is not valid or no username is
   * found.
   */
  public static extractUsernameFromJwt(token?: string | null): string | undefined {
    // Parse the token.
    const tokenPayload: any = ObjectUtils.parseJwt(token);

    // If no token payload was found, return
    // undefined.
    if (!tokenPayload) {
      return undefined;
    }

    // Extract the username from the token from
    // the username property.
    let username = tokenPayload["username"];

    // If the username is empty, then try to
    // load it from the token from the sub
    // property.
    if (StringUtils.isEmpty(username, true)) {
      username = tokenPayload["sub"];
    }

    // If the username is not empty, then
    // return it.
    if (!StringUtils.isEmpty(username, true)) {
      return username;
    }

    // Since no username was found in the
    // token, return undefined.
    return undefined;
  }

  public static getByValue = (arr: any = [], value: string) => {
    const found = _.findWhere(arr, { value }) || _.findWhere(arr, { value: value.toString() });
    return found || { value: 0, text: "no lbl" };
  };

  public static extractLabelFromObject(arr: any, keyName: string) {
    const nolbl = { display: "no lbl for " + keyName, textToRead: "no lbl" };
    // return _.find(arr, {keyName}) || arr[keyName] || nolbl
    let tempObj: any = [];
    _.find(arr, (el: { keyName: string; numOfPopupLinkPresent: number }) => {
      if (el.keyName === keyName) {
        if (el.numOfPopupLinkPresent > 0) {
          tempObj = ObjectUtils.extractURLsFromText(el);
        } else {
          tempObj = el;
        }
        return tempObj;
      }
    });
    return tempObj ? tempObj : nolbl;
  }

  public static extractURLsFromText = (object: any) => {
    let index = 0;
    const newObj = { ...object };
    const targetText = newObj.display;
    const regex = /\[!(.*?)\]/gm;
    const matchedText = targetText.match(regex);
    if (matchedText !== null) {
      const replacement = matchedText.map((el: string) => {
        let temp: any = "";
        const res: any = el.split("[!URL='").map((elm) => {
          temp = elm.split("'&&TEXTTODISPLAY='");
          return elm.length > 0 && [temp[0].replace("/", "_").toLowerCase(), temp[1].split("']")[0]];
        });
        // aria-label="${res[1][1]}"
        //onclick="handleURLClick('${temp[0]}')"
        return `<a data-tid="${temp[0]}" href="javaScript:void(0)" class="url-modifier" role="button">${res[1][1]}</a>`;
      });
      const result = targetText.replace(regex, () => {
        return replacement[index++];
      });
      newObj.display = result;
    }
    return newObj;
  };

  public static getControlId = (strValue: string) => {
    let controlId = "";
    if (strValue && strValue.length > 0) {
      let newstrValue = strValue.replace(/[^a-zA-Z ]/g, "").trim(); //Remove special charactor
      newstrValue = newstrValue.length > 25 ? newstrValue.slice(0, 25) : newstrValue;
      controlId = newstrValue.replace(/\s+/g, "-").toLowerCase();
    }
    return controlId;
  };

  //https://gist.github.com/jbutko/d7b992086634a94e84b6a3e526336da3
  public static downloadFile = (response: any, fileName: string) => {
    const fileextension = fileName.split(".").pop();
    const file = `${fileName?.split(".")[0]}-${+new Date()}.${fileextension}`;

    const url = window.URL.createObjectURL(new Blob([response], { type: "application/" + fileextension }));
    const a = document.createElement("a");
    a.href = url;
    a.download = `${file}`;
    a.click();
  };

  //Need to test
  public static openFileInNewTab = (response: any) => {
    const blob = new Blob([response], { type: "application/pdf" });
    // Chrome, FF
    const fileUrl = URL.createObjectURL(blob);
    const w = window.open(fileUrl, "_blank");
    w && w.focus();
  };

  public static makePageOrder = (data: EnumMainMenu) => {
    const menu: any[] = [];
    const keys = _.keys(data);
    const actionLinks: any[] = [];
    const screens: any[] = [];
    let ii = 0;
    let j = 0;

    const indicators: any[] = [];
    _.each(keys, (k) => {
      if (k !== "lang") {
        const menuItem = data[k];
        const subMenus = menuItem.subMenus || {};
        menu.push(menuItem);
        let link: any;
        if (menuItem.actinLink && !menuItem.actionLinks) {
          actionLinks.push(menuItem.actinLink);
          link = menuItem.actinLink;
        } else if (menuItem.actionLinks) {
          actionLinks.push(menuItem.actionLinks);
          link = menuItem.actionLinks;
        }
        screens.push({ link, name: k, order: ii + j, display: menuItem.display });
        ii++;
        _.each(subMenus, (submenuItem, key) => {
          menu.push(submenuItem);
          actionLinks.push(submenuItem.actinLink);
          if (submenuItem.actinLink && submenuItem.actinLink.indexOf("/") > -1) indicators.push(submenuItem);
          screens.push({ link: submenuItem.actinLink, name: key, order: ii + j, display: submenuItem.display });
          j++;
        });
      }
    });
    return { screens, indicators };
  };

  public static getNextScreen = (screens: any, selectedActinLink: string) => {
    let nextScreen: any = "";
    _.each(screens, (item) => {
      if (item?.link instanceof Array) {
        const obj = _.where(item.link, {
          actinLink: selectedActinLink
        });
        if (obj && obj.length !== 0) {
          nextScreen = item;
        }
      }
    });
    return nextScreen;
  };

  public static modifyJsonObject = (clientStyles: { [x: string]: string | null; }) => {
    const keys = Object.keys(clientStyles);
    for (const key of keys) {
      document.body.style.setProperty(key, clientStyles[key]);
    }
  };
}

export default ObjectUtils;
