import _ from "underscore";
import { BeneficiaryActionTypes } from "../actions";
import { BeneficiaryConstants } from "../constants";
import { beneficiaryConfigState } from "../interfaces";

// Constants
/**
 * This defines the default state
 * for the AppConfig.
 */
const defaultState: beneficiaryConfigState = {
  beneficiariesInfo: null,
  beneficiaries: null,
  beneficiariesAll: null,
  beneficiariyForm: null,
  summaryBeneficiaries:null,
  beneficiariesMultiBenefit:null,
  multiBenefitErrorMessage:null,
  flexBeneficiaryBenefitID:localStorage.getItem('flexBenefitId')?localStorage.getItem('flexBenefitId'): -1,
  packageMultibeneficiaryBenefits:JSON.parse(localStorage.getItem("multiBenList")|| "[]"),
  onSaveESign:null,
  
};

//Functions
export const beneficiaryReducer = (state: beneficiaryConfigState = defaultState, action: BeneficiaryActionTypes): beneficiaryConfigState => {
  switch (action.type) {
    case BeneficiaryConstants.GET_BENEFICIARIES:
      return {
        ...state,
        beneficiaries: action.payload
      };
    case BeneficiaryConstants.GET_BENEFICIARIES_ALL:
      return {
        ...state,
        beneficiariesAll: action.payload
      };

      case BeneficiaryConstants.DELETE_BENEFICIARY:
        const newBeneficiariesList = _.reject(state.beneficiaries.beneficiaries, (ben)=>ben.beneficiaryId === action.payload)
        return {
          ...state,
          beneficiaries: {...state.beneficiaries, beneficiaries:newBeneficiariesList},
        };

    case BeneficiaryConstants.REALLOCATE_MULTIBENEFIT:
      return {
        ...state,
        beneficiariesMultiBenefit: action.payload
      };

        case BeneficiaryConstants.SET_FLEX_BENEFICIARY_BENEFIT_ID: {
          localStorage.setItem("flexBenefitId", action.payload)
          return{
            ...state,
            flexBeneficiaryBenefitID: action.payload
          }
        }

        case BeneficiaryConstants.SET_MULTI_BENEFICIARIES_LIST: {
          localStorage.setItem("multiBenList", JSON.stringify(action.payload.beneficiaryBenefits))
          return{
            ...state,
            packageMultibeneficiaryBenefits: action.payload.beneficiaryBenefits
          }
        }

    case BeneficiaryConstants.FINISH_ENROLLMENT_FORM:
      return {
        ...state,
        beneficiariyForm: action.payload
      };

    case BeneficiaryConstants.GET_SUMMARY_BENEFICIARIES:
      return {
        ...state,
        summaryBeneficiaries: action.payload
      };

    case BeneficiaryConstants.PROCEED_TO_NEXT:
      return {
        ...state,
        multiBenefitErrorMessage: action.payload
      };
    /* E-Signature Start */
    case BeneficiaryConstants.SAVE_ESIGNATURE: {
      return {
        ...state,
        onSaveESign: action.payload
      };
    }
    /* E-Signature End */
    case BeneficiaryConstants.CREATE_BENEFICIARY:
      state.beneficiaries.beneficiaries.push(action.payload);
      return {
        ...state,
        beneficiaries: { ...state.beneficiaries, result: { Beneficiaries: state.beneficiaries.beneficiaries } }
      };

    case BeneficiaryConstants.UPDATE_BENEFICIARY:
      const benOnject = state.beneficiaries;
      benOnject["beneficiaries"] = action.payload.beneficiary;
      return {
        ...state,
        beneficiaries: benOnject
      };

    case BeneficiaryConstants.UPDATE_INDIVIDUAL_BENEFICIARY: {
      const updatedBeneficiary = action.payload.beneficiary;
      const newBeneficiariesList = state.beneficiaries?.beneficiaries?.map((beneficiary) => {
        if (beneficiary.beneficiaryId === updatedBeneficiary.beneficiaryId) {
          return {
            ...beneficiary,
            ...updatedBeneficiary
          };
        }
        return beneficiary;
      });
      return {
        ...state,
        beneficiaries: {
          ...state.beneficiaries,
          beneficiaries: newBeneficiariesList
        }
      };
    }

    default:
      return state;
  }
};
