import { BillingActionTypes } from "../actions";
import { DirectBillingConstants } from "../constants";
import { BillingConfigState } from "../interfaces";

// Constants
/**
 * This defines the default state
 * for the AppConfig.
 */

const defaultState: BillingConfigState = {
  billingInfo: null
  /* {
    result: {
      showAddButton: true,
      showDeleteButton: false,
      institutionsList: false,
      padAgreementForm: null,
      bankAccountDetails: {
        institutionConfigurationID: 0,
        institutionNumber: "",
        institutionName: null,
        transitNumber: "",
        accountNumber: "",
        wordTyped: "",
        isAgreed: false
      }
    },
    contentResource: null
  } */
};

//Functions
export const billingReducer = (state: BillingConfigState = defaultState, action: BillingActionTypes): BillingConfigState => {
  switch (action.type) {
    case DirectBillingConstants.GET_DIRECTBILLING:
      return {
        ...state,
        billingInfo: action.payload
      };

    default:
      return state;
  }
};
