// Imports

import { enrollmentActionTypes } from "../actions";
import { EventConfigConstants, PageConfigConstants, Welcome } from "../constants";
import { EventConfigState } from "../interfaces";
import { USER_TOKEN } from "../utils";

// Constants
/**
 * This defines the default state
 * for the AppConfig.
 */

const defaultState: EventConfigState = {
  basePageTitleItem: null,
  basePageTitle: null,
  stepDescription: null,
  lifeEventChangeContent: null,
  headerInfo: USER_TOKEN.get()?.employeeInfo || {},
  // pageSetting: null,
  // benefit: null,
  headerNavURL: localStorage.getItem("logOffCheck") ? "/" : "",
  nextEnrolStep: {},
  enrolmentHeader: null,
  enrolmentFooter: null,
  progressBar: null,
  miniCartInitialState: false,
  disableNext: false,
  focusInitializing: false,
  benefit: USER_TOKEN?.get()?.pageSetting?.benefit || null,
  pageSetting: USER_TOKEN?.get()?.pageSetting?.screen || Welcome
};

//Functions
export const eventReducer = (
  state: EventConfigState = defaultState,
  action: enrollmentActionTypes
): EventConfigState => {
  switch (action.type) {
    case PageConfigConstants.SET_BASE_PAGE_ITEM:
      return {
        ...state,
        basePageTitleItem: action.payload
      };

    case PageConfigConstants.SET_BASE_PAGE_TITLE:
      return {
        ...state,
        basePageTitle: action.payload
      };

    case PageConfigConstants.SET_STEP_DESCRIPTION:
      return {
        ...state,
        stepDescription: action.payload
      };

    case EventConfigConstants.SET_PAGE_SETTING:
      USER_TOKEN.setPageSetting(action.payload);
      return {
        ...state,
        pageSetting: action.payload.screen,
        benefit: action?.payload?.benefit || {}
      };
    case EventConfigConstants.GET_LIFE_EVENT_CHANGE:
      return {
        ...state,
        lifeEventChangeContent: action.payload
      };

    case PageConfigConstants.SET_HEADER_INFO:
      USER_TOKEN.setEmployeeInfo(action.payload)
      return {
        ...state,
        headerInfo: action.payload
      };
    case PageConfigConstants.SET_NAV_URL:
      return {
        ...state,
        headerNavURL: action.payload
      };

    case EventConfigConstants.NEXT_ENROLLMENT_STEP:
      return {
        ...state,
        nextEnrolStep: action.payload
      };
    case EventConfigConstants.GET_ENROLMENT_HEADER: {
      return {
        ...state,
        enrolmentHeader: action.payload
      };
    }
    case EventConfigConstants.GET_ENROLMENT_FOOTER: {
      return {
        ...state,
        enrolmentFooter: action.payload
      };
    }
    case EventConfigConstants.GET_PROGRESS_INDICATOR: {
      return {
        ...state,
        progressBar: action.payload
      };
    }

    case EventConfigConstants.SET_MINI_CART_INITIAL_STATE: {
      return {
        ...state,
        miniCartInitialState: action.payload
      };
    }

    case EventConfigConstants.FOCUS_INITIALIZING: {
      return {
        ...state,
        focusInitializing: action.payload
      };
    }

    case EventConfigConstants.DISABLE_NEXT: {
      return {
        ...state,
        disableNext: action.payload
      };
    }

    case EventConfigConstants.SET_NAV_ERROR: {
      return {
        ...state,
        nextEnrolStep: {
          navigationError: action.payload
        }
      };
    }

    default:
      return state;
  }
};
