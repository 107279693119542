import { ENDPOINTS } from "../constants/APIConstants";
// import { getBusinessProfileResponse } from "../constants";
import { EnumBankAccountDetailsObject, EnumDirectBillingContent } from "../actions";
import { axios } from "../utils";
import { billingObject } from "../interfaces";

/////////////
// Classes //
/////////////
/**
 * @description
 * This static class defines several static methods
 * which can be used to make API calls
 */
export class BillingApiCaller {
  public static async getBilling(): Promise<EnumDirectBillingContent> {
    try {
      const response: any = await axios<EnumDirectBillingContent>({
        method: "GET",
        url: ENDPOINTS.DIRECTBILLING.getDirectBilling
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async saveBilling(data: billingObject): Promise<EnumBankAccountDetailsObject> {
    try {
      const response: any = await axios<EnumBankAccountDetailsObject>({
        method: "POST",
        url: ENDPOINTS.DIRECTBILLING.saveDirectBilling,
        data
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteBilling(): Promise<EnumDirectBillingContent> {
    try {
      const response: any = await axios<EnumDirectBillingContent>({
        method: "DELETE",
        url: ENDPOINTS.DIRECTBILLING.deleteDirectBilling
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
