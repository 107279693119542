// import { ENDPOINTS } from "../constants";
import { axios } from "../utils";
import { ENDPOINTS } from "../constants/APIConstants";
/////////////
// Classes //
/////////////
/**
 * @description
 * This static class defines several static methods
 * which can be used to make API calls
 */
export class BeneficiaryApiCaller {
  public static async getBeneficiaries(benefitID: number, beneficiariesAll: boolean): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFICIARYAPIS.getBeneficiaries}&benefitID=${benefitID}&beneficiaryall=${beneficiariesAll}`;
      const response: any = await axios<any>({
        method: "GET",
        url: _url
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async finishEnrollmentForms(): Promise<any> {
    try {
      // Make the API call.
      const fihishURL = `${ENDPOINTS.CONTENTAPIS.getfinishEnrollmentForm}?formTypes=beneficiaryform`;
      const response: any = await axios<any>({ method: "GET", url: fihishURL });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getBeneficiariesAll(): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFICIARYAPIS.getBeneficiariesAll}`;
      const response: any = await axios<any>({
        method: "GET",
        url: _url
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async createBeneficiary(beneficiaryData: any): Promise<any> {
    try {
      // Make the API call.
      const addAddress =
        beneficiaryData.beneficiaryType === "charity"
          ? ENDPOINTS.BENEFICIARYAPIS.addEditCharity
          : beneficiaryData.beneficiaryType === "estate"
          ? ENDPOINTS.BENEFICIARYAPIS.addEditEstate
          : ENDPOINTS.BENEFICIARYAPIS.addEditPerson;

      if (beneficiaryData.beneficiaryType === "estate") {
        beneficiaryData = {
          BeneficiaryID: beneficiaryData.beneficiaryId,
          estate: beneficiaryData.estate
        };
      }
      if (beneficiaryData.beneficiaryType === "charity") {
        beneficiaryData = {
          BeneficiaryID: beneficiaryData.beneficiaryId,
          charity: beneficiaryData.charity
        };
      }

      const response: any = await axios<any>({
        method: "POST",
        url: addAddress,
        data: beneficiaryData
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async updateBeneficiary(beneficiaries: any, benefitID: any): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFICIARYAPIS.updateBeneficiary}${benefitID}`;
      const response: any = await axios<any>({
        method: "PUT",
        url: _url,
        data: beneficiaries
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async updateIndividualBeneficiary(beneficiary: any): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFICIARYAPIS.addEditPerson}`;
      const response: any = await axios<any>({
        method: "POST",
        url: _url,
        data: beneficiary
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  public static async deleteBeneficiary(_beneficiaryId: any, _benefitID: any): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFICIARYAPIS.deleteBeneficiary}${_beneficiaryId}&benefitID=${_benefitID}`;
      const response: any = await axios<any>({
        method: "PUT",
        url: _url
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getBeneficiaryDeclaration(btnNavigateTo: string): Promise<any> {
    try {
      // Make the API call.
      const _url = `${btnNavigateTo}`;
      const response: any = await axios<any>({ method: "GET", url: _url });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async getSummaryBeneficiariesESignature(_actionLink: string): Promise<any> {
    try {
      // Make the API call.
      const _url = `${_actionLink}`;
      const response: any = await axios<any>({ method: "GET", url: _url });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  public static async summarySaveESignature(_isAgreed: any, _wordTyped: any, _beneficiaryall: any): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFICIARYAPIS.saveESignature}`;
      const response: any = await axios<any>({
        method: "PUT",
        url: _url,
        data: { isAgreed: _isAgreed, wordTyped: _wordTyped, beneficiaryall: _beneficiaryall }
      });
      // Since no errors were found in the API
      // response, return the response data.
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public static async reallocateMultiBenefit(_benefitID: number): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFICIARYAPIS.reallocateMultiBenefit}${_benefitID}`;
      const response: any = await axios<any>({
        method: "GET",
        url: _url
      });
      // Since no errors were found in the API
      // response, return the response data.
      console.log(response?.data);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  public static async getSummaryBeneficiaries(): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFICIARYAPIS.getSummaryBeneficiaries}`;
      const response: any = await axios<any>({
        method: "GET",
        url: _url
      });
      // Since no errors were found in the API
      // response, return the response data.
      console.log(response?.data);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  public static async proceedToNextMultiBenefit(_benefitID: any): Promise<any> {
    try {
      // Make the API call.
      const _url = `${ENDPOINTS.BENEFICIARYAPIS.proceedToNextMultiBenefit}${_benefitID}`;
      const response: any = await axios<any>({
        method: "GET",
        url: _url
      });
      // Since no errors were found in the API
      // response, return the response data.
      console.log(response?.data);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
