import { KeyboardEvent } from "react";
import { languageChange, languageSwitch, toggleSideBar } from "../actions";
import { Location } from "react-router-dom";

export class EventUtils {
  /**
   * @description
   * This function will determine if the
   * given event is Enter or Space.
   *
   * @param event
   * The key event
   *
   * @returns
   * The boolean flag to denote if the
   * specified key event is
   * Enter or Space key
   */
  public static keyPressed(event: KeyboardEvent) {
    const { code } = event;
    if (code !== "Tab") event.preventDefault();
    if (code === "Enter" || code === "Space") return true;
  }

  public static changeLocale = async (locale: string, location: Location<any>) => {
    languageChange(locale);
    toggleSideBar(false);
    languageSwitch(location);
  };

  public static skipMainContentKeyDown = (e) => {
    e.which === 13 && this.skipMainContent();
  };

  public static skipMainContent = () => {
    const headingMain = document.getElementById("headingMain");
    const loginContainer = document.getElementById("login-container");
    const maincontent = document.getElementById("maincontent");
    const headingWelcome = document.getElementById("headingWelcome");
    const mainWelcome = document.getElementById("mainWelcome");
    const heading2FA = document.getElementById("heading2FA");
    const headingEnrolConf = document.getElementById("headingenrollmentconfirmation");
    const enrollmentSummary = document.getElementById("enrollment-summary");
    const mainContent = document.getElementById("main-content");
    const coverageReport = document.getElementById("coverageReport");
    const mainpage = document.getElementById("mainpage");

    if (headingMain !== undefined && headingMain !== null) {
      headingMain.setAttribute("tabIndex", "-1");
      headingMain.focus();
    } else if (loginContainer !== undefined && loginContainer !== null) {
      loginContainer.setAttribute("tabIndex", "-1");
      loginContainer.focus();
    } else if (maincontent !== undefined && maincontent !== null) {
      maincontent.setAttribute("tabIndex", "-1");
      maincontent.focus();
    } else if (headingWelcome !== undefined && headingWelcome !== null) {
      headingWelcome.setAttribute("tabIndex", "-1");
      headingWelcome.focus();
    } else if (mainWelcome !== undefined && mainWelcome !== null) {
      mainWelcome.setAttribute("tabIndex", "-1");
      mainWelcome.focus();
    } else if (heading2FA !== undefined && heading2FA !== null) {
      heading2FA.setAttribute("tabIndex", "-1");
      heading2FA.focus();
    } else if (headingEnrolConf !== undefined && headingEnrolConf !== null) {
      headingEnrolConf.setAttribute("tabIndex", "-1");
      headingEnrolConf.focus();
    } else if (enrollmentSummary !== undefined && enrollmentSummary !== null) {
      enrollmentSummary.setAttribute("tabIndex", "-1");
      enrollmentSummary.focus();
    } else if (mainContent !== undefined && mainContent !== null) {
      mainContent.setAttribute("tabIndex", "-1");
      mainContent.focus();
    } else if (coverageReport !== undefined && coverageReport !== null) {
      coverageReport.setAttribute("tabIndex", "-1");
      coverageReport.focus();
    } else if (mainpage !== undefined && mainpage !== null) {
      mainpage.setAttribute("tabIndex", "-1");
      mainpage.focus();
    }
  };
}

export default EventUtils;
