// Imports
import { statusActionTypes } from "../actions";
import { EnumStatusMessage } from "../actions/actionTypes/models/statusModels";

import { StatusConfigConstants } from "../constants";
// Constants
/**
 * This defines the default state
 * for the AppConfig.
 */

const defaultState: EnumStatusMessage = {
  message: null,
  messageType: "",
  isLoading: false
};

//Functions
export const statusReducer = (state: EnumStatusMessage = defaultState, action: statusActionTypes): EnumStatusMessage => {
  switch (action.type) {
    case StatusConfigConstants.FAILURE:
      return {
        ...state,
        message: action.payload,
        messageType: "danger"
      };

    case StatusConfigConstants.SUCCESS:
      return {
        ...state,
        message: action.payload,
        messageType: "success"
      };

    case StatusConfigConstants.DISSMISS_MESSAGE:
      return {
        ...state,
        messageType: "",
        message: null
      };

    case StatusConfigConstants.IS_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };

    default:
      return state;
  }
};

// Export
export default statusReducer;
