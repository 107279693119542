/////////////////
// Helper Code //
/////////////////
import { ObjectUtils, StringUtils } from "./";

////////////////
// Interfaces //
////////////////
/**
 * @description
 * This interface defines the inputs when
 * replacing query-parameters.
 *
 * A ReplaceQueryParamsOption contains a
 * paramName and a paramValue.
 *
 * @param paramName
 * The name of the query-parameter to be
 * replaced.
 * @param paramValue
 * The new value.
 * If this is null or undefined, that means
 * that the query-parameter is to be removed.
 * @param replaceOnly
 * The boolean flag to denote if adding this
 * query-parameter, only do so if it already
 * exists.
 */
export interface ReplaceQueryParamsOption {
  paramName: string;
  paramValue?: string;
  replaceOnly?: boolean;
}

/////////////
// Classes //
/////////////
/**
 * @description
 * This static class defines several static methods
 * which can be used to work with URLs.
 */
export class UrlUtils {
  /* ******************
   * PUBLIC FUNCTIONS *
   ********************/
  /**
   * @description
   * This function will extract the query-parameter
   * value for the specified query-parameter name
   * from the specified location.
   *
   * @param {string} paramName
   * The name of the query-parameter.
   * @param {Location} location
   * The location.
   * If this is not defined, the current windows
   * location will be used.
   *
   * @returns {string}
   * The query-parameter value.
   */
  public static getUrlQueryParamValue(paramName: string, location?: Location): string {
    // If the specified parameter-name is not empty,
    // then keep processing.
    if (!StringUtils.isEmpty(paramName)) {
      // If location is not specified, use the
      // current windows location.
      location = location || window.location;

      // Extract the URL parameters.
      const urlParams = new URLSearchParams(location.search);

      if (urlParams.has(paramName)) {
        return urlParams.get(paramName)!;
      }
    }

    return "";
  }

  /**
   * @description
   * This function will replace the query-parameters
   * specified in the options with their specified
   * values in the URL of the specified location.
   *
   * @param {Location} location
   * The location.
   * @param {ReplaceQueryParamsOption[]} options
   * The array of ReplaceQueryParamsOptions.
   *
   * If a ReplaceQueryParamsOption has a null or
   * empty value, that query-paramter will be
   * removed.
   *
   * @returns
   * The new URL.
   */
  public static determineLocation(location: Location, options: ReplaceQueryParamsOption[]): string {
    // If options are specified, then process the
    // location.
    if (ObjectUtils.isListNotEmpty(options)) {
      // Replace the URL parameters.
      const queryParamStr = UrlUtils.replaceUrlQueryParams(options, location.search);

      // Build the new location URL.
      const newLocationUrl = location.protocol + "//" + location.host + location.pathname;

      if (!StringUtils.isEmpty(queryParamStr, true)) {
        return newLocationUrl + "?" + queryParamStr;
      }

      return newLocationUrl;
    }

    return location.toString();
  }

  /**
   * @description
   * This function will replace the query-parameters
   * specified in the options with their specified
   * values in the URL of the specified location.
   *
   * @param {string} queryParams
   * The URL query parameters.
   * @param {ReplaceQueryParamsOption[]} options
   * The array of ReplaceQueryParamsOptions.
   *
   * If a ReplaceQueryParamsOption has a null or
   * empty value, that query-paramter will be
   * removed.
   *
   * @returns
   * The query-parameter string.
   */
  public static replaceUrlQueryParams(options: ReplaceQueryParamsOption[], queryParams?: string): string | undefined {
    // If options are specified, then process the
    // location.
    if (ObjectUtils.isListNotEmpty(options)) {
      // Extract the URL parameters.
      const urlParams = new URLSearchParams(queryParams);

      options.forEach((option) => {
        if (StringUtils.isEmpty(option.paramValue, true)) {
          urlParams.delete(option.paramName);
        } else if (!ObjectUtils.isTrue(option.replaceOnly) || urlParams.has(option.paramName)) {
          urlParams.set(option.paramName, option.paramValue!);
        }
      });

      // Return the URL query-parameter string.
      return urlParams.toString();
    }

    // Return the original URL query-parameter string.
    return queryParams;
  }

  /**
   * @description
   * This function will redirect the current
   * window to the specified URL.
   * If the URL is not specified, the current
   * window will be reloaded.
   *
   * @param {string} url
   * The URL to load.
   */
  public static loadPage(url?: string): void {
    // If a URL is specified, then redirect
    // the browser to that URL.
    if (!StringUtils.isEmpty(url)) {
      window.location.href = url!;

      return;
    }

    // If no URL is specified, then reload
    // the current page.
    window.location.reload();
  }

  public static updateTo = (to: string, params: any) => {
    const entries = Object.entries(params);
    let path = `${to}`;

    entries.forEach(([key, value]) => {
      path = path.replace(`:${key}`, `${value}`);
    });

    return path;
  };
}

export default UrlUtils;
