import { BenefitActionTypes } from "../actions";
import { BenefitConstants, FlexConstants } from "../constants";
import { benefitConfigState } from "../interfaces";
import { USER_TOKEN } from "../utils";

// Constants
/**
 * This defines the default state
 * for the AppConfig.
 */

const defaultState: benefitConfigState = {
  benefitWellness: null,
  showFlexData: null,
  benefitUrlList: USER_TOKEN?.get()?.benefitNavLinks || [],
  benefitsLists: USER_TOKEN?.get()?.multiBenefitList || [],
  benefit: null,
  beneficiariesInfo: null,
  smokerContent: null,
  beneficiaries: [],
  benefitSummary: null,
  flexDollars: null,
  flexDollarsSavePayload: null,
  selectedBenefitOption: null,
  enrollmentSummaryBenefits: null,
  enrollmentSummaryFlex: null,
  wellnessError: null
};

//Functions
export const benefitReducer = (
  state: benefitConfigState = defaultState,
  action: BenefitActionTypes
): benefitConfigState => {
  switch (action.type) {
    case BenefitConstants.GET_WELLNESS:
      return {
        ...state,
        benefitWellness: action.payload
      };
    case BenefitConstants.GET_SHOW_FLEX:
      return {
        ...state,
        showFlexData: action.payload
      };

    case BenefitConstants.BENEFIT_URL_LIST:
      USER_TOKEN.setBenefitNavLinks(action.payload);
      return {
        ...state,
        benefitUrlList: action.payload
      };
    case BenefitConstants.GET_MULTI_BENEFIT_DATA:
      USER_TOKEN.setMultiBenefitList(action.payload);
      return {
        ...state,
        benefitsLists: action.payload
      };
      case BenefitConstants.GET_SINGLE_BENEFIT_DATA:
        USER_TOKEN.setMultiBenefitList(action.payload);//save data for single benefit
        return {
          ...state,
          benefitsLists: action.payload
        };
    case BenefitConstants.SAVE_WAIVER_FORM:
      return {
        ...state,
        benefit: action.payload
      };
    case BenefitConstants.REMOVE_WAIVER:
      return {
        ...state,
        benefit: action.payload
      };
    case BenefitConstants.CHANGE_OPTION:
      return {
        ...state,
        benefit: action.payload
      };
    case BenefitConstants.SET_BENEFIT_OPTION:
      return {
        ...state,
        selectedBenefitOption: action.payload
      };
    case BenefitConstants.GET_SMOKER_CONTENT:
      return {
        ...state,
        smokerContent: {
          employeeSmoker: action?.payload?.employeeSmoker,
          dependentSmoker: action?.payload?.dependentSmoker
        }
      };

    case BenefitConstants.GET_BENEFIT_SUMMARY: {
      return {
        ...state,
        benefitSummary: action.payload
      };
    }
    case BenefitConstants.GET_SUMMARY_BENEFITS: {
      return {
        ...state,
        enrollmentSummaryBenefits: action.payload
      };
    }
    case BenefitConstants.GET_SUMMARY_FLEX: {
      return {
        ...state,
        enrollmentSummaryFlex: action.payload
      };
    }
    case BenefitConstants.UPDATE_WELLNESS: {
      return {
        ...state,
        wellnessError: action.payload
      };
    }
    case FlexConstants.SAVE_FLEX:
      return {
        ...state,
        flexDollarsSavePayload: action.payload
      };
    case FlexConstants.GET_FLEX: {
      return {
        ...state,
        flexDollars: action.payload
      };
    }
    case FlexConstants.UPDATE_FLEX: {
      return {
        ...state,
        flexDollars: action.payload
      };
    }
    case FlexConstants.RELOCATE_FLEX: {
      return {
        ...state,
        flexDollars: action.payload
      };
    }

    default:
      return state;
  }
};
