// Imports
import { coverageActionTypes } from "../actions/actionTypes/coverageActionTypes";
import { CoverageConfigConstants } from "../constants";
import { CoverageConfigState } from "../interfaces";
// Constants
/**
 * This defines the default state
 * for the AppConfig.
 */

const defaultState: CoverageConfigState = {
    currentCoverage: null,
    coverageList: null,
    pastCoverage:null,
    finishEnrollment:null
  };
  

//Functions
export const coverageReducer = (state: CoverageConfigState = defaultState, action: coverageActionTypes): CoverageConfigState => {

  switch (action.type) {
    case CoverageConfigConstants.REVIEW_CURRENT_COVERAGE:
      return {
        ...state,
        currentCoverage: action.payload
      };

    case CoverageConfigConstants.REVIEW_PAST_COVERAGE:
      return {
        ...state,
        pastCoverage: action.payload
      };

      case CoverageConfigConstants.REVIEW_PAST_COVERAGE_LIST:
        return {
          ...state,
          coverageList: action.payload
        };

        case CoverageConfigConstants.FINISH_ENROLLMENT:
        return {
          ...state,
          finishEnrollment: action.payload
        };

        
    default:
      return state;
  }
};

// Export
export default coverageReducer;
