import { useCallback, useEffect } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { BaseLayout } from "../containers";
import { USER_TOKEN } from "../utils";

export const PrivateRouting = ({ isSignedIn, ...rest }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { client } = USER_TOKEN?.get();

  const validateURL = useCallback(() => {
    let path: any = pathname.replace(/^(.+?)\/*?$/, "$1");

    switch (path) {
      case "/multi-factor":
      case "/changepassword":
      case "/":
        path = client !== undefined && client !== null ? `/${client}/login` : "no_client_redirect";
        break;
      case path.match("/*/login"):
        break;
      default:
        path = path?.toUpperCase() + "/login";
        break;
    }
    if (path === "no_client_redirect") {
      const { no_client_redirect }: any = window.global;
      const pattern = /^((http|https):\/\/)/;
      window.location.href = !pattern.test(no_client_redirect) ? "https://" + no_client_redirect : no_client_redirect;
      // window.location.replace(redirectUrl);
    } else {
      const isValid = matchPath({ path: "/:client/login" }, path);
      navigate(isValid?.pathname || "/403");
    }
  }, [client, navigate, pathname]);

  useEffect(() => {
    if (!isSignedIn && !rest?.skipValidate) {
      validateURL();
    }
  }, [isSignedIn, rest?.skipValidate, validateURL]);
  return <BaseLayout {...rest} />;
};

export default PrivateRouting;
