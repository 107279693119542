import { profileActionTypes } from "../actions";
import { ProfileConfigConstants } from "../constants";
import { ProfileConfigState } from "../interfaces";

// Constants
/**
 * This defines the default state
 * for the AppConfig.
 */

const defaultState: ProfileConfigState = {
  personalInfo: null,
  updatePersonalInfo: null,
  dependents: null,
  summaryDependents:null,
  summaryPersonal:null
};

//Functions
export const profileReducer = (
  state: ProfileConfigState = defaultState,
  action: profileActionTypes
): ProfileConfigState => {
  switch (action.type) {
    case ProfileConfigConstants.GET_PROFILE_CONTENT:
      return {
        ...state,
        personalInfo: action.payload
      };

    case ProfileConfigConstants.UPDATE_PERSONAL_INFO:
      return {
        ...state,
        updatePersonalInfo: action.payload
      };

    case ProfileConfigConstants.GET_DEPENDENTS:
      return {
        ...state,
        dependents: action.payload
      };

      case ProfileConfigConstants.GET_SUMARY_DEPENDENTS:
      return {
        ...state,
        summaryDependents: action.payload
      };

      case ProfileConfigConstants.GET_SUMMARY_PERSONAL:
      return {
        ...state,
        summaryPersonal: action.payload
      };
      
    default:
      return state;
  }
};
