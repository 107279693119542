// Imports
// import _ from "underscore";
import { CommonActionTypes, EnumMainMenu } from "../actions";
import { ContentDataConstants, FileUploadConfigConstants } from "../constants";
import { CommonConfigState } from "../interfaces";
import { ObjectUtils } from "../utils";
import { USER_TOKEN } from "../utils/Storage";

// Constants
/**
 * This defines the default state
 * for the AppConfig.
 */

export const defaultState: CommonConfigState = {
  contentResource: null,
  helpLink: "",
  clientDetails: JSON.parse(localStorage.getItem("style") || "{}"),
  showWarningMessage: false,
  statusCode: null,
  forgotPasswordContent: null,
  temporaryPasswordContent: null,
  helpContentResponse: null,
  contentLogin: null,
  contentHeader: null,
  contentFooter: null,
  contentWelcome: null,
  putWelcomeResponse: null,
  putWelcomeRequestObject: null,
  contentMainMenu: null,
  personalInfo: null,
  dependents: null,
  fileUploadInfoContent: null,
  uploadedFilesInfoContent: null,
  benefitWellnessInfoContent: null,
  benefitUrlList: null,
  isMenuLoaded: false,
  menu2Flag: false,
  screens: [],
  indicators: [],
  clientStyle: null,
  client: USER_TOKEN.get().client,
  contentFooterPage: null
};

//Functions
export const commonReducer = (state: CommonConfigState = defaultState, action: CommonActionTypes): CommonConfigState => {
  switch (action.type) {
    case ContentDataConstants.GET_HELP_CONTENT:
      return {
        ...state,
        helpContentResponse: action.payload
      };

    case ContentDataConstants.GET_CLIENT_DETAILS_CONTENT:
      localStorage.setItem("style", JSON.stringify(action.payload));
      return {
        ...state,
        clientDetails: action.payload
      };

    case ContentDataConstants.GET_CLIENT_NAME:
      USER_TOKEN.setClientName(action.payload)
      return {
        ...state,
        client: action.payload
      };

    case ContentDataConstants.SHOW_WARNING_MESSAGE: {
      return {
        ...state,
        showWarningMessage: action.payload
      };
    }

    case ContentDataConstants.GET_CLIENT_STYLES_CONTENT:
      localStorage.setItem("clientStyles", JSON.stringify(action.payload));
      return {
        ...state,
        clientStyle: action.payload
      };

    case ContentDataConstants.GET_LOGIN_CONTENT:
      return {
        ...state,
        contentLogin: action.payload
      };

    case ContentDataConstants.GET_HEADER_IMAGES_CONTENT:
      return {
        ...state,
        contentHeader: action.payload
      };
    case ContentDataConstants.GET_RESOURSE_CONTENT:
      return {
        ...state,
        contentResource: action.payload
      };

    case ContentDataConstants.GET_FOOTER_CONTENT:
      return {
        ...state,
        contentFooter: action.payload
      };

    case ContentDataConstants.GET_CONTENT_FOOTER_PAGE:
      return {
        ...state,
        contentFooterPage: action.payload
      };

    case ContentDataConstants.GET_WELCOME_CONTENT:
      return {
        ...state,
        contentWelcome: action.payload
      };
    case ContentDataConstants.PUT_WELCOME_CONTENT:
      return {
        ...state,
        putWelcomeResponse: action.payload
      };

    case ContentDataConstants.PUT_WELCOME_REQUESTED_OBJECT:
      return {
        ...state,
        putWelcomeRequestObject: action.payload
      };

    case ContentDataConstants.PUT_WELCOME_MESSAGES: {
      return {
        ...state,
        putWelcomeResponse: {
          ...state.putWelcomeResponse,
          contentResource: action.payload.contentResource
        }
      };
    }

    case ContentDataConstants.GET_MAIN_MENU_CONTENT:
      const po = ObjectUtils.makePageOrder(action.payload as EnumMainMenu);

      const screens = po.screens;
      const indicators = po.indicators;
      return {
        ...state,
        contentMainMenu: action.payload,
        isMenuLoaded: true,
        screens,
        indicators
      };

    case ContentDataConstants.IS_DISABLED: {
      const tempMenu = state?.contentMainMenu;

      for (const item in tempMenu) {
        if (action.payload instanceof Array) {
          if (tempMenu[item].actionLinks instanceof Array) {
            for (const index in tempMenu[item].actionLinks) {
              if (action.payload[0].actinLink === tempMenu[item].actionLinks[index].actinLink) {
                tempMenu[item].isDisabled = false;
                return {
                  ...state,
                  contentMainMenu: tempMenu
                };
              }
            }
          }
        } else if (tempMenu[item].actinLink === "") {
          for (const index in tempMenu[item].subMenus) {
            if (action.payload === tempMenu[item].subMenus[index].actinLink) {
              tempMenu[item].subMenus[index].isDisabled = false;
              return {
                ...state,
                contentMainMenu: tempMenu
              };
            }
          }
        } else {
          if (action.payload === tempMenu[item].actinLink) {
            tempMenu[item].isDisabled = false;
            return {
              ...state,
              contentMainMenu: tempMenu
            };
          }
        }
      }
      return {
        ...state
      };
    }

    case ContentDataConstants.SET_MENU2_FLAG: {
      return {
        ...state,
        menu2Flag: action.payload
      };
    }

    case ContentDataConstants.GET_FORGOT_PASSWORD_CONTENT:
      return {
        ...state,
        forgotPasswordContent: action.payload
      };

    case ContentDataConstants.GET_TEMPORARY_PASSWORD_CONTENT:
      return {
        ...state,
        temporaryPasswordContent: action.payload
      };
    case FileUploadConfigConstants.UPLOADED_FILES_INFO:
      return {
        ...state,
        uploadedFilesInfoContent: action.payload
      };
    case FileUploadConfigConstants.FILE_UPLOAD_INFO:
      return {
        ...state,
        fileUploadInfoContent: action.payload
      };

    default:
      return state;
  }
};

// Export
export default commonReducer;
