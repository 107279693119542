import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { DismissAlertComponent, WindowDialogComponent } from "../components";
import {
  Beneficiaries,
  BeneficiariesAll,
  BeneficiariesReallocate,
  BenefitShell,
  BenefitSummary,
  BenefitWellness,
  ChangePassword,
  Coverage,
  Dependents,
  ViewDirectBilling,
  EnrollmentConfirmation,
  EnrollmentSummary,
  ExternalLinks,
  FlexDollars,
  ForgotPassword,
  LifeChangeEvent,
  LoginCard,
  Logout,
  MFAAuthentication,
  ReviewCoverage,
  ReviewPersonalInfo,
  SummaryBeneficiaries,
  SummaryDependents,
  SummaryESignBeneficiaries,
  SummaryPersonal,
  TwoFASettings,
  WelcomeScreen,
  PackageMultiBeneficiaries,
  CreateDirectBilling,
  ConfirmDirectBilling
} from "../containers";
import { UploadFile } from "../containers/UploadDocument";
import { RootState } from "../reducers";
import { USER_TOKEN } from "../utils";
import PrivateRouting from "./PrivateRouting";
import SetSpinnerTransparencyClass from "./SetSpinnerTransparencyClass";

export interface RedirectProps {
  to: string;
  state?: any;
}

export const Routing = () => {
  const response = useSelector((state: RootState) => state.auth);

  const isSignedIn = USER_TOKEN.get()?.isSignedIn || response?.isSignedIn;
  // const isTFA = !response?.authResponse && (USER_TOKEN.get()?.needTwoFARegistration || USER_TOKEN.get()?.needTwoFAValidation);
  // if (isTFA) isSignedIn = !isTFA;
  const RedirectComponent = () => {
    const redirectURL = response?.redirectURL;
    if (redirectURL && (redirectURL.indexOf("http://") !== -1 || redirectURL.indexOf("https://") !== -1)) {
      window.location.href = redirectURL;
    } else {
      window.location.href = "https://" + redirectURL;
    }
    return null;
  };

  SetSpinnerTransparencyClass();
  return (
    <div id="mainpage" className="cmt-4 mb-5">
      <DismissAlertComponent />
      <WindowDialogComponent />
      <Routes>
        <Route path="/403" element={<div>403: Access Denied. Invalid Client or URL</div>} />
        <Route
          path="/:client/sso"
          element={<PrivateRouting isSignedIn={isSignedIn} path="home" hideNavigation={true} hideFlex={true} skipValidate={true} benefit={WelcomeScreen} />}
        />
        <Route path="/:client/login" element={<LoginCard />} />
        {/* <Route path="/:client/sso" element={<WelcomeScreen />} /> */}
        <Route path="/:client/forgotpassword" element={<ForgotPassword />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/logoff" element={<Logout />} />
        <Route path="/Account/RevokeToken" element={<Logout />} />
        <Route path="/outside" element={<RedirectComponent />} />
        <Route path="/Content/FooterContent" element={<ExternalLinks />} />
        <Route path="/ReviewCoverage/CurrentCoverage" element={<Coverage />} />
        <Route path="/Coverage" element={<Coverage />} />
        <Route path="/multi-factor" element={<MFAAuthentication />} />
        <Route path="/" element={<PrivateRouting isSignedIn={isSignedIn} path="home" hideNavigation={true} hideFlex={true} hidePaging={true} benefit={WelcomeScreen} />} />
        <Route path="/ReviewCoverage/PreviousCoverageList" element={<PrivateRouting isSignedIn={isSignedIn} hideFlex={true} hidePaging={true} benefit={ReviewCoverage} />} />
        <Route path="/Profile" element={<PrivateRouting isSignedIn={isSignedIn} benefit={ReviewPersonalInfo} hideFlex={true} hidePaging={true} />} />
        <Route path="/Dependent" element={<PrivateRouting isSignedIn={isSignedIn} hideFlex={true} hidePaging={true} benefit={Dependents} />} />
        <Route path="/Account/TwoFASettings" element={<PrivateRouting isSignedIn={isSignedIn} hideNavigation={true} hideFlex={true} hidePaging={true} benefit={TwoFASettings} />} />
        <Route path="/LifeChangeEvent" element={<PrivateRouting isSignedIn={isSignedIn} hideNavigation={true} hideFlex={true} hidePaging={true} benefit={LifeChangeEvent} />} />
        <Route
          path="/FileUpload/UploadedFilesInfo"
          element={<PrivateRouting isSignedIn={isSignedIn} hideNavigation={true} hideFlex={true} hidePaging={true} benefit={UploadFile} />}
        />
        <Route path="/Wellness" element={<PrivateRouting isSignedIn={isSignedIn} hidePaging={true} benefit={BenefitWellness} />} />
        <Route path="/Benefits" element={<PrivateRouting isSignedIn={isSignedIn} benefit={BenefitShell} />} />
        <Route path="/add/*" element={<PrivateRouting isSignedIn={isSignedIn} benefit={BenefitShell} />} />
        <Route path="/life/*" element={<PrivateRouting isSignedIn={isSignedIn} benefit={BenefitShell} />} />
        <Route path="/addwc/*" element={<PrivateRouting isSignedIn={isSignedIn} benefit={BenefitShell} />} />
        <Route path="/health/*" element={<PrivateRouting isSignedIn={isSignedIn} benefit={BenefitShell} />} />
        <Route path="/disability/*" element={<PrivateRouting isSignedIn={isSignedIn} benefit={BenefitShell} />} />
        <Route path="/vacation/*" element={<PrivateRouting isSignedIn={isSignedIn} benefit={BenefitShell} />} />
        <Route path="/Package/*" element={<PrivateRouting isSignedIn={isSignedIn} benefit={BenefitShell} />} />
        <Route path="/Beneficiaries" element={<PrivateRouting isSignedIn={isSignedIn} hideNavigation={true} hideFlex={true} hidePaging={true} benefit={Beneficiaries} />} />
        <Route path="/Beneficiary/All" element={<PrivateRouting isSignedIn={isSignedIn} hideFlex={true} hidePaging={true} benefit={BeneficiariesAll} />} />
        <Route path="/MultiPackageBeneficiaries" element={<PrivateRouting hideNavigation={true} isSignedIn={isSignedIn} hideFlex={true} hidePaging={true} benefit={PackageMultiBeneficiaries} />} />
        <Route
          path="/Summary/BeneficiariesESignature"
          element={<PrivateRouting isSignedIn={isSignedIn} hideNavigation={true} hideFlex={true} hidePaging={true} benefit={SummaryESignBeneficiaries} />}
        />
        <Route
          path="/Summary/Beneficiaries"
          element={<PrivateRouting isSignedIn={isSignedIn} hideNavigation={true} hideFlex={true} hidePaging={true} benefit={SummaryBeneficiaries} />}
        />

        <Route
          path="/BeneficiaryReallocate"
          element={<PrivateRouting isSignedIn={isSignedIn} hideNavigation={true} hideFlex={true} hidePaging={true} benefit={BeneficiariesReallocate} />}
        />
        <Route path="/Summary/Personal" element={<PrivateRouting isSignedIn={isSignedIn} hideNavigation={true} hideFlex={true} hidePaging={true} benefit={SummaryPersonal} />} />
        <Route path="/Summary" element={<PrivateRouting isSignedIn={isSignedIn} hideNavigation={true} benefit={BenefitSummary} />} />
        <Route
          path="/Summary/Dependents"
          element={<PrivateRouting isSignedIn={isSignedIn} hideNavigation={true} hideFlex={true} hidePaging={true} benefit={SummaryDependents} />}
        />
        <Route path="/Flex" element={<PrivateRouting isSignedIn={isSignedIn} benefit={FlexDollars} />} />
        <Route path="/EnrollmentSummary" element={<PrivateRouting isSignedIn={isSignedIn} firstPage={true} hideFlex={true} selected="summaryTab" benefit={EnrollmentSummary} />} />
        <Route
          path="/FinishedEnrollment/Forms"
          element={<PrivateRouting isSignedIn={isSignedIn} hideNavigation={true} hideFlex={true} hidePaging={true} benefit={EnrollmentConfirmation} />}
        />
        <Route
          path="/EnrollmentConfirmation"
          element={<PrivateRouting isSignedIn={isSignedIn} hideNavigation={true} hideFlex={true} hidePaging={true} benefit={EnrollmentConfirmation} />}
        />

        <Route path="/directbilling" element={<PrivateRouting isSignedIn={isSignedIn} hideFlex={true} hidePaging={true} benefit={ViewDirectBilling} />} />
        <Route path="/directbilling/create" element={<PrivateRouting isSignedIn={isSignedIn} hideFlex={true} hidePaging={true} benefit={CreateDirectBilling} />} />
        <Route path="/directbilling/confirm" element={<PrivateRouting isSignedIn={isSignedIn} hideFlex={true} hidePaging={true} benefit={ConfirmDirectBilling} />} />

        <Route path="*" element={<PrivateRouting isSignedIn={isSignedIn} hideNavigation={true} hideFlex={true} hidePaging={true} benefit={() => <div>404 Not found!</div>} />} />
      </Routes>
    </div>
  );
};

export default Routing;
